.boxselector_wrapper {
  display: flex;
  flex-flow: row wrap;
}

@media only screen and (max-width: 700px) {
  .boxselector_wrapper {
    flex-direction: column;
  }
}
