.licenses-datatable .w-sm {
  width: 50px;
}

.licenses-datatable .w-md {
  width: 100px;
}

.licenses-datatable .center {
  text-align: center;
}
