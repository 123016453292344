.slider-badge {
  top: -2px;
}

.root {
  margin-left: 15px;
  margin-top: 200px;
}

.root :global(.rc-slider-tooltip-inner) {
  height: auto;
  padding: 10px;
  background-color: var(--bg-tooltip-color);
  min-width: max-content;
  color: var(--black);
  box-shadow: var(--shadow-box-color);
}

.root :global(.rc-slider-tooltip-arrow) {
  border-top-color: var(--bg-tooltip-color);
}
