.root {
  position: relative;
}

.wrapperButton {
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
}

a.item {
  display: block;
  text-decoration: none;
  outline: initial;
  color: inherit;
}

.edit-button {
  position: absolute;
  right: 0;
  top: 5px;
}
